body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#vehicleList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.vehicleCard {
  border: 2px black solid;
  margin: 4px 4px;
  padding: 4px 4px;
}

.dispatchButton {
  background-color: rgba(0, 122, 255, 1);
  color: white;
  font-weight: bold;
}

#mapContainer {
  height: 100vh;
}

.vehicle-marker {
  max-width: 28px;
  height: auto;
  z-index: 0;
}

.mapboxgl-marker img {
  max-width: 100%;
  max-height: 100%;
}

.selected-popup > .mapboxgl-popup-content {
  background: #1f1f1f;
  color: #fff;
}

.mapboxgl-popup-tip {
  display: none;
}
